import React from "react"

import TacoBellHoliday from "/components/Holiday2020/TacoBell"
import Layout from "/components/Holiday2020/components/Layout"
import SEO from "/components/seo"

const TacoBellHoliday2020 = () => (
  <Layout backgroundColor={"white"} bannerBgColor="">
    <SEO
      title="Thesis | Holiday 2020: Taco Bell"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />

    <TacoBellHoliday />
  </Layout>
)

export default TacoBellHoliday2020
